import { deleteRequest, getRequest, postRequest, putRequest } from '..';

const createDynamicItemEndpoints = (endpointName) =>
{ 
	return {
        get: (id = undefined) => { 
            return id !== undefined
            ? getRequest(`/${endpointName}/${id}`,)
            : getRequest(`/${endpointName}`);
        },
        add: (data) => postRequest(`/${endpointName}`, data),
        edit: (data) => putRequest(`/${endpointName}`, data),
        remove: (id) => deleteRequest(`/${endpointName}/${id}`)
  };
}

export default createDynamicItemEndpoints;
<template>
  <div id="accordion" class="accordion">
    <AccordionItem
      v-for="faq in filteredItems"
      :key="faq.key"
      :faq="faq"
    
      :show-buttons="showEditButtons"

      @update="updateFaq"
      @delete="deleteFaq"
    />
  </div>

  <div
    v-if="showSubmitButton"
    class="row justify-content-end"
  >
    <div class="col-md-3">
      <button class="btn btn-success btn-sm float-end">
        Aanpassingen opslaan
      </button>
    </div>
  </div>
</template>

<script>
import AccordionItem from './Item.vue'

export default {
  name: 'Accordion',
  components: { AccordionItem },
  props: {
    items: { type: Array, default: () => [] },
    showEditButtons: { type: Boolean, default: false }
  },

  emits: [
    'update',
  ],

  computed: {
    filteredItems() { return this.items.filter(i => !i.isDeleted); }
  },

  methods: {
    updateFaq(faq) {
      this.$emit('update', { ...faq, isUpdated: true });
    },
    
    deleteFaq(faq) {
      this.$emit('update', { ...faq, isDeleted: true });
    }
  },
}
</script>
<template>
  <div class="offset-md-3 col-md-6">
    <div 
      class="card"
    >
      <div
        class="card-body"
      >
        <h1>Frequently Asked Questions</h1>
        <p>Hieronder kunnen de veel gestelde vragen van de app ingezien worden</p>
        <Accordion
          :items="faqs"
          :show-edit-buttons="true"
          @update="updateFaq"
        />
      </div>
      <div class="card-footer pt-0">
        <button 
            class="btn btn-dark btn-sm float-end d-flex align-items-center ms-2"
            @click="newFaqItem"
          >
            Item toevoegen <i class="fa fa-plus ms-2"></i>
          </button>
          <button 
            v-if="showSubmitButton"
            class="btn btn-success btn-sm float-end"
            @click="saveUpdatedItems"
          >
            Aanpassingen opslaan
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import Accordion from '@/components/UI/Accordion/Accordion.vue';

import { get, edit, add, remove } from "@/api/providers/faq";

import setRouteNameForModule from '@/helpers/route-name';

export default {
  name: 'FAQ',
  components: {
      Accordion
  },

  data() {
    return {
      faqs: [],
      showSubmitButton: false,
      key: 0
    };
  },

  beforeMount() {
    setRouteNameForModule('Faq', 'Overzicht');
  },

  mounted() {
    this.getFaqItems();
  },

  methods: {
    ...mapActions('notification', {
      addNotification: 'add',
    }),

    updateFaq(faq) {
      const index = this.faqs.findIndex(f => f.key === faq.key);
      if (index >= 0) {
        this.faqs[index] = faq;
        this.showSubmitButton = true;
      }
    },

    handleSaveSuccess() {
      this.addNotification({
        description: `FAQ is opgeslagen.`,
        icon: {
          component: 'help',
        },
      });
    },

    async getFaqItems() {
      try {
        let faqs = await get();
        this.faqs = faqs.map(faq => { return { ...faq, key: this.key++ } });
      }
      catch (error) {
        if (error.default !== undefined) error.default();
      }
    },

    async saveUpdatedItems()
    {
      try {
        await this.saveFaqItems();
        this.handleSaveSuccess();

        this.showSubmitButton = false;
      }
      catch (error) {
        if (error.default !== undefined) error.default();
      }  
    },

    async saveFaqItems() {
      const faqs = (this.faqs ?? []);

      for (const faq of faqs) {
        try {
          if (faq.isNew && !faq.isDeleted) {
            await add(faq);
            continue;
          }

          if (faq.isDeleted && !faq.isNew) {
            await remove(faq.id);
            continue;
          }

          if (faq.isUpdated) {
            await edit(faq);
            continue;
          }
        }
        catch (error) {
          throw new Error(error);
        }
      }
    },
    
    newFaqItem()
    {
      this.faqs.push({ 
        id: 0,
        title: 'Nieuw Item',
        description: 'Nieuw Item',
        isNew: true,
        key: this.key++
      });

      this.showSubmitButton = true;
    }
  }
}

</script>
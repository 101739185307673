<template>
  <div 
    class="accordion-item mb-3"
  >
    <h5 
      :id="`heading_${faq.key}`" 
      class="accordion-header"
    >
      <button 
        v-if="isInViewMode"
        class="accordion-button border-bottom font-weight-bold collapsed"
        type="button"
        :data-bs-toggle="isInViewMode ? 'collapse' : ''" 
        :data-bs-target="`#collapse_${faq.key}`" 
        aria-expanded="false"
        :aria-controls="`#collapse_${faq.key}`" 
      >
        {{ faq.title }}
        <i 
          class="collapse-close text-xs pt-1 position-absolute end-0 me-3 fa fa-plus" 
          aria-hidden="true"
        ></i>
        <i 
          class="collapse-open text-xs pt-1 position-absolute end-0 me-3 fa fa-minus" 
          aria-hidden="true"
        ></i>
      </button>

      <MaterialInput
        v-if="isInEditMode"
        v-model="titleInputContent"
        :value="title"
        is-required
        :label="titleLabel"
        class="mb-2"
        variant='static'
      />
    </h5>

    <div 
      :id="`collapse_${faq.key}`" 
      class="accordion-collapse collapse" 
      :aria-labelledby="`heading_${faq.key}`"
      data-bs-parent="#accordion" 
    >
      <div 
        v-if="isInViewMode"
        class="accordion-body text-sm opacity-8"
      >
        {{ faq.description }}
  </div>
    <MaterialTextarea
      v-if="isInEditMode"
      v-model="descriptionInputContent"
      :label="descriptionLabel"
      is-required
      variant="static"
    />
    <div>
      <button 
        v-if="showButtons"
        class="btn btn-sm btn-dark mt-2 me-2"
        @click.prevent="toggleMode"
      >
        {{ modeToggleButtonText }}
      </button>

      <button 
        v-if="showButtons"
        class="btn btn-sm btn-danger mt-2"
        @click.prevent="emitDelete"
      >
        Verwijderen
      </button>
    </div>
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput"
import MaterialTextarea from "@/components/MaterialTextarea"

const Modes = {
  EDIT: 0,
  VIEW: 1
}

const CollapseState = {
  COLLAPSED: 0,
  OPEN: 1
}

export default {
  name: 'AccordionItem',

  components: {
      MaterialInput,
      MaterialTextarea
  },

  props: {
      faq: { type: Object, required: true },

      titleLabel: { type: String, default: 'Titel' },
      descriptionLabel: { type: String, default: 'Beschrijving' },

      showButtons: { type: Boolean, default: false }
  },

  emits: [
    'update',
    'delete'
  ],

  data() {
      return {
          // These store the updated content while the fields are being edited
          titleInputContent: this.faq.title,
          descriptionInputContent: this.faq.description,

          mode: Modes.VIEW,
          state: CollapseState.COLLAPSED,
      };
  },

  computed: {
      isInViewMode() { return this.mode === Modes.VIEW; },
      isInEditMode() { return this.mode === Modes.EDIT; },

      isCollapsed() { return this.state === CollapseState.COLLAPSED; },

      modeToggleButtonText() {
          return this.mode === Modes.VIEW
          ? "Aanpassen"
          : "Opslaan";
      },
  },

  mounted() { 
    this.attachHideCollapseHook();
    this.attachShowCollapseHook();
  },

  methods: {
    toggleMode() {
        this.mode === Modes.VIEW
        ? this.enterEditMode()
        : this.exitEditMode();
    },

    enterEditMode() { this.mode = Modes.EDIT; },

    exitEditMode() { 
      if (this.mode === Modes.VIEW) return;

      this.mode = Modes.VIEW;

      this.$emit('update', 
      {
        ...this.faq,
        title: this.titleInputContent,
        description: this.descriptionInputContent,
      });
    },

    emitDelete() { this.$emit('delete', this.faq); },
    
    attachHideCollapseHook() { 
      this.$el.addEventListener('hide.bs.collapse', this.bootstrapHideCollapseHook);
      },

    attachShowCollapseHook() {
      this.$el.addEventListener('show.bs.collapse', this.bootstrapShowCollapseHook);
    },

    bootstrapHideCollapseHook() { 
      this.exitEditMode();
      this.state = CollapseState.COLLAPSED;
    },

    bootstrapShowCollapseHook() { 
      this.state = CollapseState.OPEN;  
    }
  }
}
</script>